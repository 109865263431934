import { EnumFiscalResidence } from '@blank/api'
import { CountryConfig, EnumHomeCountryCode } from '../../types'

export const CA_FR_COUNTRY_CONFIG: CountryConfig = {
  companyIdentificationNumberLength: 9,
  shouldChooseDomiciliationOnSignup: false,
  isBeta: false,
  canAddEnterpriseMandate: true,
  shouldDisplayUserFeedbackButton: false,
  canAddSponsorshipVoucherOnSignup: false,
  increaseTransferLimitTypeformId: 'M4GACcvJ',
  feedbackTypeformId: '',
  accountClosingTypeformId: 'PQMgX8c8',
  shouldDisplayAdditionalTouOnSelectOffer: false,
  fiscalResidenceOptions: [EnumFiscalResidence.FRA, EnumFiscalResidence.USA],
  homeCountryCode: EnumHomeCountryCode.FRA,
  invoicing: {
    canCreateEstimateDocument: true,
    canAddFiscalTaxesToDocument: false,
    canAddFiscalStampToDocument: false,
    shouldSelectVATExonerationCode: false,
  },
  shouldDisplayAverageBalanceDocuments: false,
  shouldBuildMicroEnterpriseCreationURLWithParams: true,
  whatsAppSupportNumber: undefined,
  canDepositCheck: false,
  shouldCustomizeCardFraudInsuranceRedirectionUrl: false,
  fileComplaintTypeformId: 'm9QIeY1f',
}
